/**
 * offers列表
 */
import Vue from 'vue'
import { Message,Page,Input,DatePicker,locale,Table,Button,Icon,Dropdown,DropdownMenu,DropdownItem } from 'iview';
import lang from 'iview/dist/locale/en-US';
locale(lang)
Vue.component('Page', Page);
Vue.component('Input', Input);
Vue.component('DatePicker', DatePicker);
Vue.component('Table', Table);
Vue.component('Button', Button);
Vue.component('Icon', Icon);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
import { formatUnit } from '@/utils/tools'
import {
    offersColumns,
    getOffersListService,
    getOffersEditStatusService,
    delOffersListService
} from '@/service/offers-service'
import Header from '@/components/Header/header.vue'
import { mapMutations } from "vuex";
const OffersList = {
    name:'OffersList',
    components:{
        Header
    },
    data() {
        return {
            formatUnit:formatUnit,
            monthValue:'', //年月值
            weekValue:'', //周值
            offersColumns:offersColumns,//thead 数据集
            offersLoading:false,
            offersStatus:false,
            offersArray:[], //搜索数据集
            selectAllCancel:[], //全选时的临时存储
            selectAllContentCancel:[], //全选时的临时存储
            btnStatus:false,
            offersParams:{  //选中参数
                cid1:1,
                searchValue:1,
                checkContentInput:[],
            },
        }
    },
    created() {
        this.SET_TYPE_ID('1')
        this.SET_LAUNCH_TAB('1')
        this.onOffersList().then()
    },
    methods: {
        ...mapMutations('launch',['SET_LAUNCH_ID','SET_LAUNCH_NAME','SET_LAUNCH_TITLE','SET_TYPE_ID','SET_LAUNCH_TAB']),
        /**
         * 点击全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectContentAll(selection) {
            this.selectAllContentCancel = selection
            for(let key in selection){
                let flag = true
                for(let index in this.offersParams.checkContentInput){
                    if(selection[key].id === this.offersParams.checkContentInput[index]){
                        flag = false
                    }
                }
                if(flag){
                    this.offersParams.checkContentInput.push(selection[key].id)
                }
            }
        },
        /**
         * 点击取消全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectContentAllCancel() {
            let selection = this.selectAllContentCancel
            for(let key in selection){
                for(let index in this.offersParams.checkContentInput){
                    if(selection[key].id === this.offersParams.checkContentInput[index]){
                        this.offersParams.checkContentInput.splice(index,1);
                    }
                }
            }
        },
        /**
         * 选中某以一项时触发
         * selection: 以选项数据
         * row: 刚选择的数据
         * some() 方法用于检测数组中的元素是否满足指定条件
         * 注意： some() 不会对空数组进行检测。
         * 注意： some() 不会改变原始数组。
         * @param selection，row
         */
        onSelectContent(selection,row) {
            let mediaList = this.offersParams.checkContentInput.some((e)=>{
                return e.id === row.id
            })
            if(!mediaList){
                this.offersParams.checkContentInput.push(row.id)
            }
        },
        /**
         * 取消选中某一项时触发
         * selection: 以选项数据
         * row: 取消选择的项数据
         * 遍历当前选中数组 如有相同则在当前数组的指定位置进行删除
         * @param selection，row
         */
        onSelectContentCancel(selection,row) {
            for(let key in this.offersParams.checkContentInput){
                if(this.offersParams.checkContentInput[key] === row.id){
                    this.offersParams.checkContentInput.splice(key,1);
                }
            }
        },
        /**
         * 删除数据
         */
        onContentDelete(){
            if(this.offersParams.checkContentInput.length === 0){
                Message.error('Please select')
            }else{
                this.offersStatus = true;
            }
        },
        /**
         * 删除数据确认
         */
        onContentDeleteConfirm() {
            if(this.offersParams.checkContentInput.length === 0){
                Message.error('Please select');
                return;
            }
            this.onDelOffersList().then()
        },
        /**
         * 返回至列表页
         */
        onBack() {
            this.$router.back()
        },
        /**
         * 修改状态
         * @param value
         * @param id
         */
        onSelectItem(value,id) {
            this.onOffersEditStatus(value,id).then()
        },
        /**
         * 添加数据
         */
        onAddContentList(){
            this.SET_LAUNCH_ID('')
            this.$router.push('/offers/edit')
        },
        /**
         * 跳转编辑
         * @param id
         */
        onEditContent(id) {
            this.SET_LAUNCH_ID(id)
            this.$router.push('/offers/edit')
        },

        /**
         * 获取Offers数据
         * @returns {Promise<void>}
         */
        async onOffersList() {
            try {
                this.offersLoading = true
                let params = {}
                const {code,data} = await getOffersListService(params)
                if(code === 1){
                    this.offersArray = data
                }
                this.offersLoading = false
            }catch (error){
                this.offersLoading = false
                console.log(error)
            }
        },
        /**
         * 修改列表状态
         * @returns {Promise<void>}
         */
        async onOffersEditStatus(status,id) {
            try {
                let params = {
                    id:id,
                    status:status,
                }
                const {code} = await getOffersEditStatusService(params)
                if(code === 1){
                    this.onOffersList().then()
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 删除search数据
         * @returns {Promise<void>}
         */
        async onDelOffersList() {
            try {
                if(this.offersParams.checkContentInput.length === 0){
                    Message.error('Please select');
                    return;
                }
                this.btnStatus = true
                let params = {
                    'ids':this.offersParams.checkContentInput,
                }
                const {code} = await delOffersListService(params)
                if(code === 1){
                    Message.success('successful')
                    this.onOffersList().then()
                    this.offersParams.checkContentInput = []
                    this.offersStatus = false
                }
                this.btnStatus = false
            }catch (error){
                this.btnStatus = false
                console.log(error)
            }
        },
    },
}
export default OffersList


/**
 * offers头数据
 * @type {*[]}
 */
export const offersColumns = [
    {
        title: 'id',
        width:140,
        key: 'planId'
    },
    {
        title: 'Start',
        width:80,
        slot:'startTime',
    },
    {
        title: 'End',
        width:80,
        slot:'endTime',
    },
    {
        title: 'Store / Brand',
        width:140,
        key: 'source'
    },
    {
        title: 'Status',
        slot:'status',
    },
    {
        type: 'selection',
        width:60,
        align: 'center'
    },
    {
        title:' ',
        width:120,
        slot:'btn',
    }
]
